// resetConfigurations.js
'use strict';

import getOperationData from '../selector/getOperationData.js';
import setConfigurations from '../action/setConfigurations.js';
import { REMOVE_OPERATION_DATA, MERGE_OPERATION_DATA } from '../ActionTypes.js';

import {
  CONFIG_PRIORITY_CONFIGURE,
  CONFIG_PRIORITY_PRESENCE_CLIENT,
  CONFIG_PRIORITY_PRESENCE_USER,
} from '../resource/configPriority.js';

/**
 * Reset configurations
 * @kind action
 * @return {Promise} Action promise.
 */
const resetConfigurations = () => async (dispatch, getState) => {
  const configData = {
    ['configuration-group-id']: getOperationData(
      getState(),
      ['config'],
      'defaultConfigKey'
    ),
    ['configuration-groups']: getOperationData(
      getState(),
      ['config'],
      'configurationGroups'
    )?.[CONFIG_PRIORITY_PRESENCE_CLIENT],
  };
  dispatch({
    type: REMOVE_OPERATION_DATA,
    payload: {
      selectPath: [
        'config',
        'configurationGroups',
        CONFIG_PRIORITY_PRESENCE_USER,
      ],
    },
  });
  dispatch({
    type: MERGE_OPERATION_DATA,
    payload: {
      selectPath: ['config'],
      data: {
        remoteConfigMerged: {
          [CONFIG_PRIORITY_CONFIGURE]: false,
          [CONFIG_PRIORITY_PRESENCE_CLIENT]: false,
          [CONFIG_PRIORITY_PRESENCE_USER]: false,
        },
        remoteConfigMerging: {
          [CONFIG_PRIORITY_CONFIGURE]: false,
          [CONFIG_PRIORITY_PRESENCE_CLIENT]: false,
          [CONFIG_PRIORITY_PRESENCE_USER]: false,
        },
      },
    },
  });
  return dispatch(
    setConfigurations({
      configData,
      priority: CONFIG_PRIORITY_PRESENCE_CLIENT,
    })
  );
};

export default resetConfigurations;
