// persist.js
'use strict';

import { addBreadcrumb } from '@sentry/browser';

import env from '../resource/env.js';
import { persist as persistDebug } from '../resource/debug.js';
import { Severity } from '../resource/sentry.js';

const persistLog = persistDebug.extend('log');

let isInitialized = false;

const isProd = 'production' === env.NODE_ENV;
const isServer = typeof window === 'undefined';
const _version = `tag:${env.TAG_NAME}`;

// Keys are shortened because we don't want to expose them on dev panel
const persistedKeys = [
  '_fpId', // Fingerprint JS Pro ID
  '_fpIdOpenSource', // Fingerprint JS Open Source ID
  '_dtState', // Flix Disturbing State
  '_revenueKeeper',
  '_shouldTrackMixpanelUnavailable',
  '_twvpc', // "Tracked watermark validation pixel counts"
];

const captureExceptionWithSentry = async (
  error,
  { fingerprint, extras } = {}
) => {
  const { withScope } = await import('../partial/@sentry/browser/withScope.js');
  const { captureException } = await import(
    '../partial/@sentry/browser/captureException.js'
  );
  if (!fingerprint && !extras) {
    return captureException(error);
  } else {
    return new Promise(resolve => {
      withScope(scope => {
        if (fingerprint) scope.setFingerprint(fingerprint);
        if (extras) scope.setExtras(extras);
        return resolve(captureException(error));
      });
    });
  }
};

const getLocalforage = async () => {
  const { default: localforage } = await import('localforage');
  if (!isInitialized) {
    isInitialized = true;
    localforage.config();
    localforage.ready().catch(error => {
      const isSupportIndexedDB = localforage.supports(localforage.INDEXEDDB);
      const isSupportLocalStorage = localforage.supports(
        localforage.LOCALSTORAGE
      );
      captureExceptionWithSentry(error, {
        fingerprint: ['persist', 'localforage-ready'],
        extras: {
          isSupportIndexedDB,
          isSupportLocalStorage,
        },
      });
    });
  }
  return localforage;
};

export const clearState = async ({ keepPersisted = true } = {}) => {
  try {
    const localforage = await getLocalforage();
    if (keepPersisted) {
      // Load current values of items need to be persisted over logouts
      let persistedItemsMap = {};
      for (const key of persistedKeys) {
        try {
          const value = await localforage.getItem(key);
          if (value !== null) {
            persistedItemsMap[key] = value;
          }
        } catch (error) {
          // Discard values if encounter errors
          captureExceptionWithSentry(error, {
            fingerprint: ['persist', 'clearState', 'persisting'],
          });
        }
      }

      // Clear Localforage
      await localforage.clear();

      // Restore persisted values
      for (const key in persistedItemsMap) {
        await localforage.setItem(key, persistedItemsMap[key]);
      }
    } else {
      await localforage.clear();
    }
  } catch (error) {
    captureExceptionWithSentry(error, {
      fingerprint: ['persist', 'clearState'],
    });
  }
};

const getIsDBExist = () => {
  const log = persistLog.extend('getIsDBExist');
  log('init');
  return Promise.race([
    new Promise(resolve => {
      log('check localStorage db version');
      if (isServer || !window.localStorage) {
        resolve(false);
      }

      const version = localStorage.getItem('_version');
      log('localStorage version', version);

      !!version && resolve(true);
    }),
    // TODO: should remove db check after everyone has localStorage '_version'.
    new Promise(resolve => {
      log('check localforage db exist');
      if (isServer || !window.indexedDB?.open) {
        resolve(false);
      }
      // ref: https://stackoverflow.com/a/17473952
      const request = window.indexedDB.open('localforage'); // default name of localforage
      request.onerror = () => {
        resolve(false);
      };
      request.onblocked = () => {
        resolve(false);
      };
      request.onupgradeneeded = e => {
        e.target.transaction.abort();
        log('localforage db not exist');
        resolve(false);
      };
      request.onsuccess = () => {
        request.result.close();
        log('localforage db exist');
        resolve(true);
      };
    }),
  ]);
};

export const loadState = async () => {
  const log = persistLog.extend('loadState');
  log('init');
  try {
    const isDBExist = await getIsDBExist();
    log('isDBExist', isDBExist);
    if (!isDBExist) {
      return {};
    }

    const localforage = await getLocalforage();
    log('getLocalforage');
    const reducerVersion = await localforage.getItem('_version');
    log('reducerVersion', reducerVersion);

    if (reducerVersion && _version !== reducerVersion) {
      const me = (await localforage.getItem('me')) || {};
      const operations = (await localforage.getItem('operations')) || {};
      const accessToken =
        (await localforage.getItem('_accessToken')) ||
        (await localforage.getItem('me'))?.token;
      const refreshToken =
        (await localforage.getItem('_refreshToken')) ||
        (await localforage.getItem('me'))?.token;

      const firstId = operations?.shorts?.firstId;
      if (firstId) {
        addBreadcrumb({
          category: 'ShortAd',
          level: Severity.Debug,
          message: 'load first id from indexedDB (outdated version).',
          data: {
            firstId,
          },
        });
      }

      log('loaded for outdated version');
      return {
        me: { ...me, token: accessToken, refreshToken },
        operations,
      };
    }

    const reducerKeys = (await localforage.getItem('_reducerKeys')) || [];
    const reducers = await Promise.all(
      reducerKeys.map(async reducerKey => ({
        key: reducerKey,
        data: await localforage.getItem(reducerKey),
      }))
    );
    log('loaded for current version');
    const result = reducers.reduce((current, reducer) => {
      current[reducer.key] = reducer.data;
      return current;
    }, {});

    const firstId = result?.operations?.shorts?.firstId;
    if (firstId) {
      addBreadcrumb({
        category: 'ShortAd',
        level: Severity.Debug,
        message: 'load first id from indexedDB (current version).',
        data: {
          firstId,
        },
      });
    }

    return result;
  } catch (error) {
    captureExceptionWithSentry(error, {
      fingerprint: ['persist', 'loadState'],
    });
    return {};
  }
};

const throttleTime = 1000; // TODO: remote config
let nextSaveTimestamp = Date.now() + throttleTime;
let saveTimeout = undefined;
export const saveState = async ({ state }) => {
  const log = persistLog.extend('saveState');
  log('init');

  clearTimeout(saveTimeout);
  nextSaveTimestamp = Date.now() + throttleTime;

  const reducerKeys = Object.keys(state) || [];
  try {
    const localforage = await getLocalforage();
    log('getLocalforage');
    // Remove unrecognized items in localforage (could be persisted items from previous versions)
    const removeKeys = ((await localforage.keys()) || []).filter(
      key =>
        ![
          ...persistedKeys,
          ...reducerKeys,
          '_reducerKeys',
          '_version',
          '_accessToken',
          '_refreshToken',
        ].includes(key)
    );
    log('removeKeys');

    const result = await Promise.all([
      ...removeKeys.map(key => localforage.removeItem(key)),
      ...reducerKeys.map(reducerKey =>
        localforage.setItem(reducerKey, state[reducerKey])
      ),
      localforage.setItem('_reducerKeys', reducerKeys),
      localforage.setItem('_version', _version),
      localforage.setItem('_accessToken', state.me.token),
      localforage.setItem('_refreshToken', state.me.refreshToken),
    ]);
    if (!isServer && window.localStorage) {
      localStorage.setItem('_version', _version);
    }

    const operationsState = await localforage.getItem('operations');
    const savedFirstId = operationsState?.shorts?.firstId;
    const firstIdinState = state?.operations?.shorts?.firstId;
    if (savedFirstId !== firstIdinState) {
      addBreadcrumb({
        category: 'ShortAd',
        level: Severity.Debug,
        message: 'save first id in indexedDB.',
        data: {
          savedFirstId,
          firstIdinState,
        },
      });
    }

    log('finish');
    return result;
  } catch (error) {
    captureExceptionWithSentry(error, {
      fingerprint: ['persist', 'saveState'],
    });
    return null;
  }
};

export const throttleSaveState = async ({ getState }) => {
  clearTimeout(saveTimeout);
  saveTimeout = setTimeout(
    () => {
      saveState({ state: getState() });
    },
    Math.max(0, nextSaveTimestamp - Date.now())
  );
};

/**
 * Get item from storage engine
 * @param {string} key
 */
export const getItem = async key => {
  const localforage = await getLocalforage();
  return await localforage.getItem(key);
};

/**
 * Set item into storage engine
 * @param {string} key
 * @param {*} value
 */
export const setItem = async (key, value) => {
  if (!isProd && persistedKeys.indexOf(key) === -1) {
    // (Dev environment only check)
    throw new Error(`The key ${key} is not presented in persistedKeys.`);
  }
  const localforage = await getLocalforage();
  await localforage.setItem(key, value);
};

/**
 * Remove item from storage engine
 * @param {string} key
 */
export const removeItem = async key => {
  const localforage = await getLocalforage();
  await localforage.removeItem(key);
};

export default {
  loadState,
  saveState,
  throttleSaveState,
  clearState,
  setItem,
  getItem,
  removeItem,
};
