// getRegisterData.js
'use strict';
import { Collection } from 'immutable';
import createCachedSelector from '../resource/createCachedSelector.js';

/**
 * Select register data by data key
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} dataKey - data key.
 * @return {any} The selected register data.
 */
const getRegisterData = createCachedSelector(
  state => state.getIn(['operations', 'register']),
  (state, dataKey) => dataKey,
  (register, dataKey) => {
    let result = register?.get(dataKey);
    if ('emailDidYouMean' === dataKey) {
      const email = register.get('email');
      result = register.getIn(['emailOtp', email, 'didYouMean']);
    } else if ('isEmailSuggested' === dataKey) {
      const email = register.get('email');
      const emailDidYouMean = register.getIn(['emailOtp', email, 'didYouMean']);
      result = email === emailDidYouMean;
    }
    return result instanceof Collection ? result.toJS() : result;
  }
)((state, dataKey) => dataKey);

export default getRegisterData;
