// transitRegisterEmailLogout.js
'use strict';
import logout from '../action/logout.js';
import addModal from '../action/addModal.js';
import removeModals from '../action/removeModals.js';
import { register as registerModal } from '../resource/modalId.js';

/**
 * Transit register email logout
 * @kind action
 * @param {string} {action} - action param. ['coming', 'goback', 'logout']
 * @return {Promise} Action promise.
 */
const transitRegisterEmailLogout =
  ({ action }) =>
  async dispatch => {
    switch (action) {
      case 'coming': {
        dispatch(
          addModal({
            id: registerModal.REGISTER_EMAIL_LOGOUT,
            transitionStatus: 'coming',
            isHigherThanAll: true,
          })
        );
        return dispatch(
          removeModals({
            ids: [registerModal.EMAIL_VERIFY],
            transitionStatus: 'closing',
          })
        );
      }
      case 'goback': {
        dispatch(
          addModal({
            id: registerModal.REGISTER_EMAIL,
            transitionStatus: 'backing',
            isHigherThanAll: true,
          })
        );
        return dispatch(
          removeModals({
            ids: [registerModal.REGISTER_EMAIL_LOGOUT],
            transitionStatus: 'reversing',
          })
        );
      }
      case 'logout': {
        setTimeout(
          () =>
            dispatch(logout({ via: 'register', pathname: location.pathname })),
          600 // TODO: remote config
        );
        return dispatch(
          removeModals({
            ids: [registerModal.REGISTER_EMAIL_LOGOUT],
            transitionStatus: 'leaving',
          })
        );
      }
      default:
        return { type: '' };
    }
  };

export default transitRegisterEmailLogout;
